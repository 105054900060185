// Ajax oriented mixin for use in components requiring server access
export default {
  methods: {
    formIsValid: form => {
      // then we cycle through all the fields to determine whether the form as a whole is valid
      for(let fld in form)
        if(form[fld].valid!==undefined && !form[fld].valid) return false
      
      return true
    },
  }
}