<template>
  <div>
    <div id="wa_titleBarSmall"></div>

    <div class="wa_explanationBand wa_compact">
      <div>
        <h2 class="wa_header">Privacy policy</h2>
        We demonstrate our commitment to our customers' privacy by providing this statement. Please read the following to learn more about our privacy policy. This policy covers how 2NAPPS treats personal information that we collect and receive. 2NAPPS will use your personal information only in ways that are compatible with this privacy policy.<br>
        <br>
        2NAPPS policies and procedures for handling customer information have been created with the understanding that Internet technologies are still evolving and that Internet business methods are continuing to evolve to meet the needs and opportunities of the changing technologies. As a result, 2NAPPS's policies and procedures are subject to change. By connecting to 2NAPPS's website, you consent to the collection and use of information by 2NAPPS as specified in this section.
      </div>
    </div>

    <div class="wa_explanationBand wa_compact">
      <div>
        <h3 class="wa_smallHeader">Information collection</h3>
        2NAPPS is the sole owner of the information collected on www.web-appointments.com. 2NAPPS collects information from users at several different points on its website. 2NAPPS explicitly asks when it needs information that personally identifies you. This information may include, for example, your email address, name, postal address, and telephone number. 2NAPPS also may ask for non-personal information.<br>
        <br>
        Your IP address is captured when you visit this site. It is used to help diagnose problems with our server, to administer the website, to track the IP origin of abusive behavior, such as spam, and to track the IP origin of an unlawful activity, such as an attempt to violate the website's security.<br>
        <br>
        Personally identifiable information collected by 2NAPPS will be stored and processed on our computers' secured databases.
      </div>
    </div>

    <div class="wa_explanationBand wa_compact">
      <div>
        <h3 class="wa_smallHeader">Data security</h3>
        2NAPPS will protect the confidentiality of its customers' information to the fullest extent possible and consistent with the law and the legitimate interests of 2NAPPS, its partners, its employees and other customers of 2NAPPS's services.<br>
        <br>
        We take every precaution to protect our users’ information. When users submit sensitive information via our website, their information is protected both online and offline.<br>
        <br>
        When our order form asks customers to enter sensitive information, that information is encrypted and is protected with the best encryption software in the industry: Secure Socket Layer ("SSL"). While on a secure page, such as our order form, the lock icon on the bottom of web browsers such as Netscape Navigator and Microsoft Internet Explorer becomes locked, as opposed to unlocked, or open, when users are just surfing.<br>
        <br>
        While we use SSL encryption to protect sensitive information online, we also do everything in our power to protect our users' information offline. All of our visitors’ information (not just the sensitive information) is restricted in our offices. Only employees who need the information to perform a specific job are granted access to personally identifiable information. All employees are kept up-to-date on our security and privacy practices.
      </div>
    </div>

    <div class="wa_explanationBand wa_compact">
      <div>
        <h3 class="wa_smallHeader">Information use</h3>
        2NAPPS uses your personal information to:<br>
        <br>
        a - provide services you have requested<br>
        b - communicate with you (only if such communication is essential to the operation of the service)<br>
        c - collect customer feedback<br>
        <br>
        2NAPPS is committed to keeping user's personally identifiable information confidential. Your personally identifiable information is not shared with any third party. 2NAPPS will not sell, trade, divulge any information stored in our database. 2NAPPS will not disclose its customers' personal and account information unless 2NAPPS is required to do so by law, or in the good faith belief that such action is necessary to protect or defend the interests of 2NAPPS or its customers.<br>
        <br>
        All customer (private individuals and professionals) contact information and appointment information is kept confidential. Private individuals and professionals can only get information related to their own appointments.
      </div>
    </div>

    <div class="wa_explanationBand wa_compact">
      <div>
        <h3 class="wa_smallHeader">Use of cookies</h3>
        A cookie is a piece of data stored on the visitor’s computer tied to information about the visitor. 2NAPPS will only set cookies on the visitors' computers to identify them temporarily when they attempt to access the customer area. This is a necessary step and the cookie is destroyed as soon as the customer leaves the customer area by using the "log out" button. When cookies are disabled, an other temporary identification method is used.
      </div>
    </div>

    <div class="wa_explanationBand wa_compact">
      <div>
        <h3 class="wa_smallHeader">Banking information</h3>
        2NAPPS does never get to know any of the customer's banking information. All payments are handled by third parties: PayPal, gold exchangers, or MerchantGold (our financial partner). Their privacy policies and encryption methods, or lack thereof, are the sole responsibility of the customer to check. 2NAPPS may not be held liable for damages whatsoever incurred as a result of using their services.
      </div>
    </div>

    <div class="wa_explanationBand wa_compact">
      <div>
        <h3 class="wa_smallHeader">Children</h3>
        2NAPPS does not knowingly collect or solicit personally identifiable information from children under 18 years of age, except as permitted by destination country's law. You must be 18 years or older to use the service. If you are under the age of 18, you hereby represent that you are using the service with the express consent of a parent or guardian.
      </div>
    </div>

    <SignupBar/>
  </div>
</template>

<script>
import SignupBar from '../components/SignupBar'

export default {
  name: 'Privacy',
  components: {
    SignupBar
  }
}
</script>