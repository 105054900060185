<template>
  <div>
    <div id="wa_titleBarSmall"></div>

    <div class="wa_explanationBand wa_compact">
      <div>
        <h2 class="wa_header">Terms and conditions</h2>
        Your access to and browsing through the websites at <a href="https://web-appointments.com">web-appointments.com</a> and <a href="https://instant-scheduling.com" target="_blank">instant-scheduling.com</a> constitute your full acceptance of these Terms and Conditions. We may periodically change these Terms, so please check them from time to time as your continued use of the website constitutes your acceptance of such changes.<br>
        <br>
        This website is owned and operated by 2NAPPS. All materials, designs, text and images contained in the website are the property of 2NAPPS, and are protected by copyright and other intellectual property laws. You may not copy, modify, download, reproduce, transmit or distribute in any way any content, in whole or in part, without our permission.<br>
        <br>
        We encourage establishment of external links to our website, in which the appearance, position, and other aspects of the link may not be such as to damage the goodwill associated with the name and trademarks of 2NAPPS. We reserve the right to disable any link in our sole discretion.
      </div>
    </div>

    <div class="wa_explanationBand wa_compact">
      <div>
        <h3 class="wa_smallHeader">Agreement, and obligations of the contracting parties</h3>
        Your placement of an order indicates your acceptance of these Terms and Conditions. The Term of the agreement commences on the date on which you create your account.<br>
        <br>
        1 - Our obligations:<br>
        <br>
        We will undertake every reasonable action to guarantee the highest quality of service possible. We will also gather customer input from time to time to make changes such as to improve the functionality of the Service.<br>
        <br>
        2 - Your obligations:<br>
        <br>
        You shall provide us with your current, complete, accurate and true personal information. You shall refrain from knowingly damaging any other user's account in any way. You may not sell or resell the Service.
      </div>
    </div>

    <div class="wa_explanationBand wa_compact">
      <div>
        <h3 class="wa_smallHeader">Price</h3>
        All fees are payable in advance. You are responsible for any applicable federal, state, and local taxes, charges and duties, or special requirements. All such duties and taxes shall be paid by you.<br>
        <br>
        All prices will be quoted in American Dollars. All charges related to exchange rate fluctuations, and possible fees charged by your bank, or any other intermediary, are at your expense.<br>
        <br>
        2NAPPS will be offering an extra month free for all new accounts, at the end of which you may initiate a payment to buy additional time from us. You have no obligation to do so.
      </div>
    </div>

    <div class="wa_explanationBand wa_compact">
      <div>
        <h3 class="wa_smallHeader">Payment</h3>
        You will pay for our Services by PayPal or credit card. All credit cards payments are processed by PayPal.<br>
        <br>
        2NAPPS shall not be liable for any loss or damage arising from any transaction between you and your bank, PayPal, or any other intermediary.<br>
        <br>
        You pay a fee that entitles you to use the Service for a set period of time. A couple of days before the expiry date, a warning email will inform you the Service period you have paid for is about to expire. You are then invited to buy additional time before the expiry date. You are solely responsible for automatic payments you may have set up with PayPal or any other institution.<br>
        <br>
        Payment by PayPal and credit card:<br>
        <br>
        Use the appropriate automatic payment button in your billing area. Follow the instructions, and your account will automatically be credited with the additional time you purchased.<br>
        To make sure that your schedule will not be taken offline if you happen to experience any payment issues (unusual and unforeseeable delays, temporary lack of funds...), 2NAPPS will extend the validity period by ten (10) days after your next billing date. You can then use this time extension to fix the issue. Your failure to pay past that deadline will automatically disable your account.<br>
        <br>
        Chargebacks, payment reversals:<br>
        <br>
        A payment reversal or chargeback will lead to either your account being terminated without refund of any fees paid in advance, or the removal of available time from your account, at our sole discretion.
      </div>
    </div>

    <div class="wa_explanationBand wa_compact">
      <div>
        <h3 class="wa_smallHeader">Termination</h3>
        You may cancel your membership at any time, without condition or restriction, simply by not purchasing additional time. 2NAPPS shall not refund to you any fees paid in advance.<br>
        <br>
        We reserve the right to terminate your membership, in our sole discretion, for any violation of these Terms and Conditions. 2NAPPS shall not refund to you any fees paid in advance.<br>
        <br>
        Under any other circumstances, refunds and free time extensions may be processed on a case-by-case basis in our sole discretion, with no obligation on our part to do so.
      </div>
    </div>

    <div class="wa_explanationBand wa_compact">
      <div>
        <h3 class="wa_smallHeader">Member account and security</h3>
        You are responsible for maintaining the confidentiality of your password and account, and are fully responsible for all activities that occur under your password and account. You agree to immediately notify us of any unauthorized use of your password or account or any other breach of security. You agree to exit from your account at the end of each visit to this website. 2NAPPS will not be liable for any loss or damage arising from your failure to comply with this policy.
      </div>
    </div>

    <div class="wa_explanationBand wa_compact">
      <div>
        <h3 class="wa_smallHeader">General provisions</h3>
        These general Terms and Conditions are governed exclusively by the laws of England, United Kingdom. All purchases using Web-appointments.com shall be exclusively governed by the laws of England, United Kingdom. You expressly agree that exclusive jurisdiction for any claim or dispute with 2NAPPS, or relating in any way to your account or your use of Web-appointments.com, resides in the courts of England, United Kingdom.<br>
        <br>
        Should any term or provision hereof be deemed invalid, void or unenforceable either in its entirety or in a particular application, the remainder of these Terms and Conditions shall nonetheless remain in full force and effect.
      </div>
    </div>

    <div class="wa_explanationBand wa_compact">
      <div>
        <h3 class="wa_smallHeader">Special provisions</h3>
        1 - Limitation of liability:<br>
        <br>
        2NAPPS shall not be liable for any failure to provide the Service, at any time or from time to time, that is caused by any of the following:<br>
        <br>
        a - Act or omission of a service provider or other third party;<br>
        b - Equipment, network or facility: failure, upgrade, modification, shortage, relocation;<br>
        c - Service, equipment, network or facility failure caused by the loss of power to you;<br>
        d - Force majeure events such as (but not limited to) strikes, fire, war, Internet service disruption, power outage, insurrection or civil disorder, flood, riot, government actions;<br>
        e - Software faults and programming errors;<br>
        f - Any other cause that is beyond 2NAPPS's control.<br>
        <br>Under such circumstances, fees are not refundable.<br>
        <br>
        2 - Modifications to Offer:<br>
        <br>
        2NAPPS reserves the right at any time to:<br>
        <br>
        a - Discontinue, temporarily or permanently the Service. 2NAPPS shall not be liable for it;<br>
        b - Modify the Service (or any part thereof) with or without notice to you;<br>
        c - Modify the Service fees, upon notice to you via email. 2NAPPS shall not be liable if you do not receive an email. Such modification would be effective as of your next billing date.
      </div>
    </div>

    <div class="wa_explanationBand wa_compact">
      <div>
        <h3 class="wa_smallHeader"></h3>
        
      </div>
    </div>

    <div class="wa_explanationBand wa_compact">
      <div>
        <h3 class="wa_smallHeader"></h3>
        
      </div>
    </div>

    <div class="wa_explanationBand wa_compact">
      <div>
        <h3 class="wa_smallHeader"></h3>
        
      </div>
    </div>

    <SignupBar/>
  </div>
</template>

<script>
import SignupBar from '../components/SignupBar'

export default {
  name: 'Terms',
  components: {
    SignupBar
  }
}
</script>