import { render, staticRenderFns } from "./SUIfield.vue?vue&type=template&id=4aa925ff&scoped=true&"
import script from "./SUIfield.vue?vue&type=script&lang=js&"
export * from "./SUIfield.vue?vue&type=script&lang=js&"
import style0 from "./SUIfield.vue?vue&type=style&index=0&id=4aa925ff&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4aa925ff",
  null
  
)

export default component.exports