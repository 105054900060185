<template>
  <div>
    <div id="wa_titleBarSmall"></div>

    <div class="wa_explanationBand wa_compact">
      <div>
        <h2 class="wa_header">Start a trial account</h2>
        We give you the first 30 days for free. After that, you can decide to buy additional time if we proved the right fit for you.<br><br>We're here to help, feel free to give us a holler at any time via our <a href="mailto:support@instant-scheduling.com?subject=Questions" target="_blank">help page</a>.
      </div>
    </div>

    <div class="wa_explanationBand wa_compact">
      <div class="wa_form wa_positive">
        <div>First a little bit about you, the admin user:</div>
        <div class="wa_fieldLine"><div class="wa_field wa_required" v-bind:class="wa_errorClasses(form.name)">
          <SUIfield focused type="text" required v-model.trim="form.name.data" @keyup="form.name.typed=true" @keyup.enter="wa_submitForm" @validation="form.name.valid = $event" placeholder="first, last names"/>
        </div></div>
        <div class="wa_fieldLine"><div class="wa_field wa_required" v-bind:class="wa_errorClasses(form.email)">
          <SUIfield type="email" required :validations="['email']" v-model.trim="form.email.data" @keyup="form.email.typed=true" @keyup.enter="wa_submitForm" @validation="form.email.valid = $event" placeholder="email@domain.com"/>
        </div></div>
        <div class="wa_fieldLine"><div class="wa_field wa_required" v-bind:class="wa_errorClasses(form.emailRepeat)">
          <SUIfield type="email" required :match="form.email.data" :validations="['email']" v-model.trim="form.emailRepeat.data" @keyup="form.emailRepeat.typed=true" @keyup.enter="wa_submitForm" @validation="form.emailRepeat.valid = $event" placeholder="email@domain.com"/>
        </div></div>
        <br>
        <div>Now choose a password (type it twice):</div>
        <div class="wa_fieldLine"><div class="wa_field wa_required" v-bind:class="wa_errorClasses(form.pwd)">
          <SUIfield type="password" name="pwd" required v-model.trim="form.pwd.data" @keyup="form.pwd.typed=true" @keyup.enter="wa_submitForm" @validation="form.pwd.valid = $event" placeholder="longer is better"/>
        </div></div>
        <div class="wa_fieldLine"><div class="wa_field wa_required" v-bind:class="wa_errorClasses(form.pwdRepeat)">
          <SUIfield type="password" name="pwdRepeat" :match="form.pwd.data" required v-model.trim="form.pwdRepeat.data" @keyup="form.pwdRepeat.typed=true" @keyup.enter="wa_submitForm" @validation="form.pwdRepeat.valid = $event" placeholder="type it again"/>
        </div></div>
        <br>
        <div>And then a bit about the business:</div>
        <div class="wa_fieldLine"><div class="wa_field wa_required" v-bind:class="wa_errorClasses(form.businessName)">
          <SUIfield type="text" required v-model.trim="form.businessName.data" @keyup="form.businessName.typed=true" @keyup.enter="wa_submitForm" @validation="form.businessName.valid = $event" placeholder="business name"/>
        </div></div>
        <div class="wa_fieldLine"><div class="wa_field" v-bind:class="wa_errorClasses(form.website)">
          <SUIfield type="url" v-model.trim="form.website.data" @keyup="form.website.typed=true" @keyup.enter="wa_submitForm" @validation="form.website.valid = $event" placeholder="web address"/>
        </div></div>
        <br>
        <div class="wa_center"><router-link to="Terms" target="_blank">terms & conditions</router-link></div>
        <br>
        <div class="wa_submit wa_positive" @click="wa_submitForm" v-if="!this.loading && !this.serverError">sign up</div>
        <div class="wa_submit_message" v-if="this.loading">please wait</div>
        <div class="wa_submit_error" v-if="this.serverError">Sorry, an error occurred. Please <a href="mailto:support@instant-scheduling.com?subject=Signup" target="_blank">contact us</a> with your information and we'll set you up manually until the issue can be fixed.</div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import SUIfield from '../SUI_v1/components/SUIfield'

import Forms from '../mixins/Forms'
//import Ajax from '../mixins/Ajax'

export default {
  name: 'Signup',
  components: {
    SUIfield
  },

  mixins: [Forms],

  data: function() {
    return {
      // sent: false,
      loading: false,
      serverError: false,
      form: {
        name: {
          data: '',
          typed: false,
          valid: null
        },email: {
          data: '',
          typed: false,
          valid: null
        },emailRepeat: {
          data: '',
          typed: false,
          valid: null
        },pwd: {
          data: '',
          typed: false,
          valid: null
        },pwdRepeat:{
          data: '',
          typed: false,
          valid: null
        },businessName: {
          data: '',
          typed: false,
          valid: null
        },website: {
          data: '',
          typed: false,
          valid: null
        }
      }
    }
  },

  methods: {
    wa_errorClasses: function(field){
      // console.log(field)
      return {
        'wa_error': !field.valid && field.typed,
        'wa_valid': field.valid && field.typed
      }
    },

    wa_submitForm: function(){
      // we handle the form submission
      // WA reseller code: WAIWA

      if(!this.formIsValid(this.form)) {
        // console.log('NOT OK')
        return false
      }

      this.loading = true

      var component = this  // necessary to access the component scope in the JSONP callback
      
      // then we submit the form data
      $.ajax({
        url: "https://instant-scheduling.com/api/2/admin/new_account.php",
        
        // The name of the callback parameter specified by IS
        jsonp: "jsoncallback",
        
        // Tell jQuery we're expecting JSONP
        dataType: "jsonp",
        
        // Tell YQL what we want and that we want JSON
        data: {
          business_name: this.form.businessName.data,
          business_website: this.form.website.data,
          user_name: this.form.name.data,
          user_email: this.form.email.data,
          user_password: this.form.pwd.data,
          reseller_code: "WAIWA",
          tz_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
          //tz_offset: -new Date().getTimezoneOffset()/60,
          //country_code: "<?php echo $_SERVER["HTTP_CF_IPCOUNTRY"]; ?>"
        },
        
        // Work with the response
        success: function(response) {
          component.loading = false
          // if all is well, we show the thank you page, otherwise the error message
          if(response.success)
            component.$router.push('Thankyou');
          else
            component.serverError = true
        },
        
        error: function(response) {
          console.log('error')
          component.loading = false
          component.serverError = true
        }
      });
    }
  },

  created: function() {
    let script = document.createElement('script')
    script.src = '//ajax.googleapis.com/ajax/libs/jquery/2.1.4/jquery.min.js'
    script.async = true // defaults to true anyway
    document.body.append(script)
  }
}
</script>