<template>
  <div>
    <input :autofocus="focused" :type="type" v-model="inputVal" @keyup="$emit('keyup',$event)" @blur="$emit('blur')" :placeholder="placeholder"/>
  </div>
</template>

<script>
export default {
  name: 'SUIfield',
  
  props: {
    placeholder: String,

    type: String,

    focused: Boolean,

    validations: Array,
    required: Boolean,
    match: String,

    value: String,
  },

  computed: {
    valid: function() {
      let valid = true
      if(this.value && this.value.length>0) {
        if(Array.isArray(this.validations) && this.validations.length>0) this.validations.forEach(validation => {
          switch (validation) {
            case "email":
              if(this.value.match(/^(\w+)([\-+.][\w]+)*@(\w[\-\w]*\.){1,5}([A-Za-z]){2,6}$/)==null) {
                // console.log("email issue")
                valid = false // this allows us to know whether to exit the parent function (see below)
                return false  // this is only here to get out of the foreach loop early
              }
              break
          }
        })
        if(!valid) return false // if any of the validation tests above failed, we can exit early
      } else if(this.required && (!this.value || this.value.length==0)) {
        // console.log("required issue")
        return false
      }

      if(this.match && (this.value != this.match)) {
        // console.log("match issue")
        return false
      }
      
      // console.log("all good")
      return true
    },

    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },

  watch: {
    // This watcher is required as the computed property does not attempt to update unless it is used elsewhere in the code..
    //  or watched (the $emit cannot be just included in the computed property's function reliably)
    /*valid: function(newData,oldData) {
      // this doesn't work reliably at all, so we are actually emitting it in the computed method itself as well
      this.$emit('validation', newData)
    }*/
    valid: {
      handler(newData,oldData) {
        // this doesn't work reliably at all, so we are actually emitting it in the computed method itself as well
        // console.log("emitted")
        this.$emit('validation', newData)
      },immediate: true
    }
  },
}
</script>

<style scoped>
input {
  width: 100%;
  margin: 0;
  outline: none;
  -webkit-appearance: none;
  resize: none;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 0;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}
</style>
